(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _debounce = require('../../../public/js/helpers/debounce');

var _deepExtend = require('../../../public/js/helpers/deep-extend');

var _forEach = require('../../../public/js/helpers/for-each');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EmPanels = function () {
	/**
  * Run the setup() function, passing along the options.
  * @param  {Object} options Object literal containing option overrides.
  */
	function EmPanels(options) {
		var _this = this;

		_classCallCheck(this, EmPanels);

		this.defaults = {
			selectors: {
				container: 'em-panels',
				tabsModifier: 'em-panels--tabs',
				accordionsModifier: 'em-panels--accordions',
				control: 'em-panel__control',
				controlActive: 'active',
				panel: 'em-panel',
				panelActive: 'active',
				nav: 'em-panels__nav',
				controls: 'em-panels__controls'
			},
			tabsToAccordions: true,
			accordionsToTabs: false
		};

		this.options = (0, _deepExtend.deepExtend)({}, this.defaults, options);
		this.selectors = this.options.selectors;

		this.containers = document.querySelectorAll('.' + this.selectors.container);
		(0, _forEach.forEach)(this.containers, function (i, container) {
			if (container.classList.contains(_this.selectors.tabsModifier) && _this.options.tabsToAccordions) {
				container.dataset.panelType = 'tabs';
				container.dataset.toggle = true;
			} else if (container.classList.contains(_this.selectors.accordionsModifier) && _this.options.accordionsToTabs) {
				container.dataset.panelType = 'accordions';
				container.dataset.toggle = true;
			}
		});

		this.controls = document.querySelectorAll('.' + this.selectors.control);
		(0, _forEach.forEach)(this.controls, function (i, control) {
			control.addEventListener('click', function (e) {
				_this.handleControlClick(e);
			});
		});

		window.addEventListener('resize', (0, _debounce.debounce)(this.handlePanelsLayout));
		window.addEventListener('EmComponents:loaded', this.handlePanelsLayout);
	}

	/**
  * Given a control element, get it's corresponding panel element, and the active control and panel.
  * @param  {Node} control	Target control element.
  * @return {Object}         Object containing target control and panel, as well as active control and panel, if available.
  */


	_createClass(EmPanels, [{
		key: 'getPanelData',
		value: function getPanelData(control) {
			var els = {
				container: control.closest('.' + this.selectors.container),
				targetPanelObject: {},
				activePanelObjects: []
			};

			els.targetPanelObject.controls = els.container.querySelectorAll('[aria-controls=' + control.getAttribute('aria-controls') + ']');
			els.targetPanelObject.panel = document.getElementById(control.getAttribute('aria-controls'));

			/**
    * Get all active panels and controls within this container and add them as panel objects.
    */
			var activePanels = els.container.querySelectorAll('.' + this.selectors.panel + '.' + this.selectors.panelActive);
			(0, _forEach.forEach)(activePanels, function (i, panel) {
				els.activePanelObjects.push({
					panel: panel,
					controls: document.querySelectorAll('[aria-controls="' + panel.id + '"]')
				});
			});

			return els;
		}

		/**
   * Toggle the selectors and aria attributes of the panel and it's controls.
   * @param  {Object} panelObject Object containing the controls and panel to toggle.
   */

	}, {
		key: 'togglePanel',
		value: function togglePanel(panelObject) {
			var _this2 = this;

			/**
    * Toggle panel selectors and aria attribues.
    */
			panelObject.panel.classList.toggle(this.selectors.panelActive);
			panelObject.panel.setAttribute('aria-hidden', !(panelObject.panel.getAttribute('aria-hidden') == 'true'));

			/**
    * Toggle control selectors and aria attributes.
    */
			(0, _forEach.forEach)(panelObject.controls, function (i, control) {
				control.classList.toggle(_this2.selectors.controlActive);
				control.setAttribute('aria-selected', !(control.getAttribute('aria-selected') == 'true'));
				control.setAttribute('aria-expanded', !(control.getAttribute('aria-expanded') == 'true'));
			});
		}

		/**
   * Tab click-event handler.
   * @param  {Event} e
   */

	}, {
		key: 'handleControlClick',
		value: function handleControlClick(e) {
			var _this3 = this;

			/**
    * Get the target control and panel.
    * @type {Object}
    */
			var panelData = this.getPanelData(e.target);

			/**
    * Check if the target panel is also the active panel.
    * @type {Boolean}
    */
			var isActive = panelData.targetPanelObject.controls[0].classList.contains(this.selectors.controlActive);

			/**
    * Check if container contains an active panel.
    * @type {Boolean}
    */
			var hasActive = panelData.activePanelObjects.length > 0 ? true : false;

			/**
    * If the container is an accordion, it can toggle itself, otherwise, it can't.
    * @type {Boolean}
    */
			var canToggleSelf = panelData.container.classList.contains(this.selectors.accordionsModifier) ? true : false;

			/**
    * Force toggle if the target is active and canToggleSelf is true.
    */
			if (isActive && canToggleSelf) {
				this.togglePanel(panelData.targetPanelObject);
			}

			/**
    * Otherwise, if target isn't active OR canToggleSelf is true...
    */
			else if (!isActive || canToggleSelf) {
					/**
      * ...check if another panel is active, and if so toggle it...
      */
					if (hasActive) {
						(0, _forEach.forEach)(panelData.activePanelObjects, function (i, panel) {
							_this3.togglePanel(panel);
						});
					}

					/**
      * ...and then toggle the target.
      */
					this.togglePanel(panelData.targetPanelObject);
				}
		}

		/**
   * Toggle between accordions and tabs.
   * @param  {Node} 	container 	Element to toggle
   * @param  {String} oldClass  	Class to remove
   * @param  {String} newClass  	Class to add
   */

	}, {
		key: 'togglePanelsLayout',
		value: function togglePanelsLayout(container, oldClass, newClass) {
			container.classList.remove(oldClass);
			container.classList.add(newClass);
		}

		/**
   * Update panels layout based on screen size.
   * @param  {Event} e
   */

	}, {
		key: 'handlePanelsLayout',
		value: function handlePanelsLayout(e) {
			/**
    * Get panels instance from global window variable.
    * @type {Object}
    */
			var panels = window.EmComponents.components.panels.instance;

			/**
    * Shorter alias for panels.options.selectors
    * @type {Object}
    */
			var selectors = panels.options.selectors;

			/**
    * Loop through each container and check if it should toggle based on screensize.
    */
			(0, _forEach.forEach)(panels.containers, function (i, container) {
				/**
     * Make sure that we actually want to toggle the layout for this container.
     */
				if (container.dataset.toggle == 'true') {
					var nav = container.querySelector('.' + selectors.nav);
					var controls = container.querySelector('.' + selectors.controls);

					/**
      * Check if controls is wider than nav, or not, and trigger the layout toggle.
      */
					if (controls.clientWidth > nav.clientWidth) {
						panels.togglePanelsLayout(container, selectors.tabsModifier, selectors.accordionsModifier);
					} else {
						panels.togglePanelsLayout(container, selectors.accordionsModifier, selectors.tabsModifier);
					}
				}
			});
		}
	}]);

	return EmPanels;
}();

window.addEventListener('EmComponents:ready', function (e) {
	window.EmComponents.addComponent('panels', EmPanels, {});
});

},{"../../../public/js/helpers/debounce":2,"../../../public/js/helpers/deep-extend":3,"../../../public/js/helpers/for-each":4}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.debounce = debounce;
/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
function debounce(func) {
	var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
	var immediate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

	var timeout;
	return function () {
		var context = this,
		    args = arguments;
		var later = function later() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.deepExtend = deepExtend;
/**
 * Vanilla extend function.
 *
 * @link http://youmightnotneedjquery.com/
 *
 * @param  {Object} out
 * @return {Object}
 */
function deepExtend(out) {
    out = out || {};

    for (var i = 1; i < arguments.length; i++) {
        var obj = arguments[i];

        if (!obj) {
            continue;
        }

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (_typeof(obj[key]) === 'object') {
                    out[key] = deepExtend(out[key], obj[key]);
                } else {
                    out[key] = obj[key];
                }
            }
        }
    }

    return out;
};

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.forEach = forEach;
/**
 * Loop through an array/nodelist and call the passed callback function.
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
function forEach(array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]);
  }
}

},{}]},{},[1])

