/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
export function debounce(func, wait = 50, immediate = false) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
