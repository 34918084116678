import { debounce } from '../../../public/js/helpers/debounce';
import { deepExtend } from '../../../public/js/helpers/deep-extend';
import { forEach } from '../../../public/js/helpers/for-each';

class EmPanels {
	/**
	 * Run the setup() function, passing along the options.
	 * @param  {Object} options Object literal containing option overrides.
	 */
	constructor(options) {
		this.defaults = {
			selectors: {
				container: 			'em-panels',
				tabsModifier:		'em-panels--tabs',
				accordionsModifier:	'em-panels--accordions',
				control: 			'em-panel__control',
				controlActive: 		'active',
				panel: 				'em-panel',
				panelActive: 		'active',
				nav:				'em-panels__nav',
				controls:			'em-panels__controls'
			},
			tabsToAccordions: true,
			accordionsToTabs: false
		};

		this.options = deepExtend({}, this.defaults, options);
		this.selectors = this.options.selectors;

		this.containers = document.querySelectorAll(`.${this.selectors.container}`);
		forEach(this.containers, (i, container) => {
			if(container.classList.contains(this.selectors.tabsModifier) && this.options.tabsToAccordions) {
				container.dataset.panelType = 'tabs';
				container.dataset.toggle = true;
			} else if(container.classList.contains(this.selectors.accordionsModifier) && this.options.accordionsToTabs) {
				container.dataset.panelType = 'accordions';
				container.dataset.toggle = true;
			}
		});

		this.controls = document.querySelectorAll(`.${this.selectors.control}`);
		forEach(this.controls, (i, control) => {
			control.addEventListener('click', (e) => {
				this.handleControlClick(e);
			});
		});

		window.addEventListener('resize', debounce(this.handlePanelsLayout));
		window.addEventListener('EmComponents:loaded', this.handlePanelsLayout);
	}

	/**
	 * Given a control element, get it's corresponding panel element, and the active control and panel.
	 * @param  {Node} control	Target control element.
	 * @return {Object}         Object containing target control and panel, as well as active control and panel, if available.
	 */
	getPanelData(control) {
		const els = {
			container: control.closest(`.${this.selectors.container}`),
			targetPanelObject: {},
			activePanelObjects: []
		};

		els.targetPanelObject.controls = els.container.querySelectorAll(`[aria-controls=${control.getAttribute('aria-controls')}]`);
		els.targetPanelObject.panel = document.getElementById(control.getAttribute('aria-controls'));

		/**
		 * Get all active panels and controls within this container and add them as panel objects.
		 */
		const activePanels = els.container.querySelectorAll(`.${this.selectors.panel}.${this.selectors.panelActive}`);
		forEach(activePanels, (i, panel) => {
			els.activePanelObjects.push({
				panel,
				controls: document.querySelectorAll(`[aria-controls="${panel.id}"]`)
			});
		});

		return els;
	}

	/**
	 * Toggle the selectors and aria attributes of the panel and it's controls.
	 * @param  {Object} panelObject Object containing the controls and panel to toggle.
	 */
	togglePanel(panelObject) {
		/**
		 * Toggle panel selectors and aria attribues.
		 */
		panelObject.panel.classList.toggle(this.selectors.panelActive);
		panelObject.panel.setAttribute('aria-hidden', !(panelObject.panel.getAttribute('aria-hidden') == 'true'));

		/**
		 * Toggle control selectors and aria attributes.
		 */
		forEach(panelObject.controls, (i, control) => {
			control.classList.toggle(this.selectors.controlActive);
			control.setAttribute('aria-selected', !(control.getAttribute('aria-selected') == 'true'));
			control.setAttribute('aria-expanded', !(control.getAttribute('aria-expanded') == 'true'));
		});
	}

	/**
	 * Tab click-event handler.
	 * @param  {Event} e
	 */
	handleControlClick(e) {
		/**
		 * Get the target control and panel.
		 * @type {Object}
		 */
		const panelData = this.getPanelData(e.target);

		/**
		 * Check if the target panel is also the active panel.
		 * @type {Boolean}
		 */
		const isActive = panelData.targetPanelObject.controls[0].classList.contains(this.selectors.controlActive);

		/**
		 * Check if container contains an active panel.
		 * @type {Boolean}
		 */
		const hasActive = panelData.activePanelObjects.length > 0 ? true : false;

		/**
		 * If the container is an accordion, it can toggle itself, otherwise, it can't.
		 * @type {Boolean}
		 */
		const canToggleSelf = panelData.container.classList.contains(this.selectors.accordionsModifier) ? true : false;

		/**
		 * Force toggle if the target is active and canToggleSelf is true.
		 */
		if(isActive && canToggleSelf) {
			this.togglePanel(panelData.targetPanelObject);
		}

		/**
		 * Otherwise, if target isn't active OR canToggleSelf is true...
		 */
		else if(!isActive || canToggleSelf) {
			/**
			 * ...check if another panel is active, and if so toggle it...
			 */
			if(hasActive) {
				forEach(panelData.activePanelObjects, (i, panel) => {
					this.togglePanel(panel);
				});
			}

			/**
			 * ...and then toggle the target.
			 */
			this.togglePanel(panelData.targetPanelObject);
		}
	}

	/**
	 * Toggle between accordions and tabs.
	 * @param  {Node} 	container 	Element to toggle
	 * @param  {String} oldClass  	Class to remove
	 * @param  {String} newClass  	Class to add
	 */
	togglePanelsLayout(container, oldClass, newClass) {
		container.classList.remove(oldClass);
		container.classList.add(newClass);
	}

	/**
	 * Update panels layout based on screen size.
	 * @param  {Event} e
	 */
	handlePanelsLayout(e) {
		/**
		 * Get panels instance from global window variable.
		 * @type {Object}
		 */
		const panels = window.EmComponents.components.panels.instance;

		/**
		 * Shorter alias for panels.options.selectors
		 * @type {Object}
		 */
		const selectors = panels.options.selectors;

		/**
		 * Loop through each container and check if it should toggle based on screensize.
		 */
		forEach(panels.containers, (i, container) => {
			/**
			 * Make sure that we actually want to toggle the layout for this container.
			 */
			if(container.dataset.toggle == 'true') {
				const nav = container.querySelector(`.${selectors.nav}`);
				const controls = container.querySelector(`.${selectors.controls}`);

				/**
				 * Check if controls is wider than nav, or not, and trigger the layout toggle.
				 */
				if(controls.clientWidth > nav.clientWidth) {
					panels.togglePanelsLayout(container, selectors.tabsModifier, selectors.accordionsModifier);
				} else {
					panels.togglePanelsLayout(container, selectors.accordionsModifier, selectors.tabsModifier);
				}
			}
		});
	}
}

window.addEventListener('EmComponents:ready', (e) => {
	window.EmComponents.addComponent('panels', EmPanels, {});
});
