/**
 * Vanilla extend function.
 *
 * @link http://youmightnotneedjquery.com/
 *
 * @param  {Object} out
 * @return {Object}
 */
export function deepExtend(out) {
    out = out || {};

    for (var i = 1; i < arguments.length; i++) {
        const obj = arguments[i];

        if(!obj) {
			continue;
		}

        for(var key in obj) {
            if(obj.hasOwnProperty(key)) {
                if(typeof obj[key] === 'object') {
                    out[key] = deepExtend(out[key], obj[key]);
                } else {
                    out[key] = obj[key];
                }
            }
		}
    }

    return out;
};
